import { computed, toRefs, reactive, onMounted } from 'vue';
import { mapPersonalDetails } from '@/utils/formatters';
import { useSdk } from './use-mycure';
const ACCOUNTS_SERVICE_NAME = 'accounts';
const PERSONAL_DETAILS_SERVICE_NAME = 'personal-details';
const SERVICE_NAME = 'authentication';

function parseOnboardingFields (dotNotation) {
  const keys = dotNotation.split('.');
  const result = {};

  let currentObj = result;

  for (let i = 0; i < keys.length - 1; i++) {
    currentObj[keys[i]] = {};
    currentObj = currentObj[keys[i]];
  }

  // Set the innermost field as undefined
  currentObj[keys[keys.length - 1]] = Date.now();

  return result;
}

const state = reactive({
  currentUser: null,
  currentUserUID: '',
});

export function useAuthentication () {
  const sdk = useSdk();

  state.currentUserUID = computed(() => {
    return state.currentUser?.uid || globalThis.localStorage.getItem('usr:uid');
  });

  const doctorProfessionalDetailsOnboardingDone = computed(() => {
    return !!state.currentUser?.onboarding?.doctorOnboarding?.professionalDetails;
  });

  const doctorPersonalDetailsOnboardingDone = computed(() => {
    return !!state.currentUser?.onboarding?.doctorOnboarding?.personalDetails;
  });

  const doctorWelcomeOnboardingDone = computed(() => {
    return !!state.currentUser?.onboarding?.doctorOnboarding?.welcome;
  });

  const doctorOnboardingDone = computed(() => {
    return (
      doctorProfessionalDetailsOnboardingDone.value &&
      doctorPersonalDetailsOnboardingDone.value
    );
  });

  const isAccountPatient = computed(() => {
    const apps = state.currentUser?.apps || [];
    return apps.includes('easyjoey-patient');
  });

  async function init () {
    const user = await sdk?.currentUser(true);
    state.currentUser = user;
    const personalDetails = await getPersonalDetails(user?.uid);
    const data = {
      ...state.currentUser,
      ...personalDetails,
    };
    state.currentUser = {
      ...mapPersonalDetails(data),
    };
    return state.currentUser;
  }

  async function signout () {
    await sdk?.signout();
    state.currentUser = null;
  }

  async function getPersonalDetails (uid) {
    const result = await sdk?.get(PERSONAL_DETAILS_SERVICE_NAME, uid);
    return result;
  }

  async function checkUniqueIdentity (email) {
    const result = await sdk?.create(SERVICE_NAME, {
      identityKey: 'email',
      identity: email,
      action: 'checkUniqueIdentity',
    });

    return result;
  }

  async function generateAPIToken (organization, opts) {
    const result = await sdk?.create(SERVICE_NAME, {
      action: 'generateAPIToken',
      expiresIn: opts?.expiresIn || '1day',
      organization,
    });

    return result?.accessToken;
  }

  async function changePassword (newPassword, oldPassword) {
    const uid = state.currentUser?.uid;
    const result = await sdk?.update(ACCOUNTS_SERVICE_NAME, uid, {
      newPassword,
      oldPassword,
    });

    return result;
  }

  async function sendPasswordResetEmail (email) {
    const result = await sdk?.create(SERVICE_NAME, {
      action: 'sendPasswordResetEmail',
      email,
      acceptUrl: `${globalThis.location.origin}/authentication-management/verify-password-reset`,
    });

    return result;
  }

  async function applyActionCode (code, payload) {
    const result = await sdk?.create(SERVICE_NAME, {
      action: 'applyActionCode',
      code,
      payload,
    });

    return result;
  }

  async function updateOnboarding (field) {
    const uid = state.currentUser?.uid;

    const result = await sdk?.update(ACCOUNTS_SERVICE_NAME, uid, {
      onboarding: {
        ...parseOnboardingFields(field),
      },
    });

    state.currentUser = {
      ...state.currentUser,
      ...result,
    };
  }

  return {
    doctorProfessionalDetailsOnboardingDone,
    doctorPersonalDetailsOnboardingDone,
    doctorWelcomeOnboardingDone,
    doctorOnboardingDone,
    isAccountPatient,
    ...toRefs(state),
    init,
    sendPasswordResetEmail,
    checkUniqueIdentity,
    changePassword,
    applyActionCode,
    signout,
    updateOnboarding,
    generateAPIToken,
  };
};
